/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Defect } from '../../../models/defects';
import serialize from '../../../utils/slateSerializer';

interface ViewNewLayoutMelDetailsProps {
  defect: Defect;
  isMelTableVisible: boolean;
}

const Wrapper = styled.div`
  table,
  tr,
  td,
  th {
    border: 1px solid;
    border-collapse: collapse;
  }
  td,
  th {
    padding-left: 4px;
    padding-top: 4px;
  }
  table.header-overview {
    text-align: center;
  }
  th {
    border-bottom: 2px solid;
  }
  h4 {
    text-decoration: underline;
  }
  table {
    width: 100%;
  }
  img {
    max-width: 100%;
  }
  display: ${({ isMelTableVisible }): string => (isMelTableVisible ? 'block' : 'none')};
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const TopBox = styled.div`
  border: 1px solid #000;
  border-bottom: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomBox = styled.div`
  border: 1px solid #000;
  border-top: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 500;
  margin: 20px 0;
`;

const Text = styled.div`
  font-size: 12px;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const ViewNewLayoutMelDetails: React.FC<ViewNewLayoutMelDetailsProps> = ({ defect, isMelTableVisible }) => {
  let chapter_number;
  let section_number;
  let subsection_number;
  let subsubsection;
  let rectifications;
  let title;
  let rectification_id;

  const item = defect.book_item;

  if (item) {
    chapter_number = item.chapter_number;
    section_number = item.section_number;
    subsection_number = item.subsection_number;
    subsubsection = item.subsubsection;
    rectifications = item?.rectifications;
    title = item.title;
    rectification_id = item?.rectification_id;
  }

  let melRec = rectifications?.length ? rectifications[0] : null;
  if (rectification_id) melRec = rectifications.find((recItem) => recItem.id === rectification_id);

  let procedureText = '';
  if (melRec?.operational_procedure_json.length) procedureText += '(O)';
  if (melRec?.maintenance_procedure_json.length) procedureText += '(M)';
  let chapterNumber = chapter_number?.toString();
  if (chapter_number?.toString() && chapter_number?.toString()?.length === 1) {
    chapterNumber = `0${chapter_number}`;
  }
  let sectionNumber = section_number?.toString();
  if (section_number?.toString() && section_number?.toString()?.length === 1) {
    sectionNumber = `0${section_number}`;
  }
  let subSectionNumber = subsection_number?.toString();
  if (subsection_number?.toString() && subsection_number?.toString()?.length === 1) {
    subSectionNumber = `0${subsection_number}`;
  }

  let displayTitleNumber = `${chapterNumber}-${sectionNumber}-${subSectionNumber}`;
  if (subsubsection) displayTitleNumber += `-${subsubsection}`;
  return (
    <Wrapper isMelTableVisible={isMelTableVisible}>
      <Title>{`${displayTitleNumber} ${title || ''}`}</Title>
      <GridDiv>
        <TopBox>Interval</TopBox>
        <TopBox>Installed</TopBox>
        <TopBox>Required</TopBox>
        <TopBox>Procedure</TopBox>
        <BottomBox>{melRec?.interval}</BottomBox>
        <BottomBox>{melRec?.number_installed}</BottomBox>
        <BottomBox>{melRec?.number_required}</BottomBox>
        <BottomBox>{procedureText || '-'}</BottomBox>
      </GridDiv>
      {melRec?.remarks_json ? (
        <>
          {melRec?.remarks_json.map((entry) => {
            return <Text marginTop="20px" dangerouslySetInnerHTML={{ __html: serialize(entry) }} />;
          })}
        </>
      ) : null}
      {melRec?.placard_procedure_json ? (
        <>
          <Header>PLACARDING</Header>
          {melRec?.placard_procedure_json.map((entry) => {
            return <Text dangerouslySetInnerHTML={{ __html: serialize(entry) }} />;
          })}
        </>
      ) : null}
      {melRec?.operational_procedure_json.length ? (
        <>
          <Header>OPERATIONAL PROCEDURE (O)</Header>
          {melRec?.operational_procedure_json.map((entry) => {
            return <Text dangerouslySetInnerHTML={{ __html: serialize(entry) }} />;
          })}
        </>
      ) : null}
      {melRec?.maintenance_procedure_json.length ? (
        <>
          <Header>MAINTENANCE PROCEDURE (M)</Header>
          {melRec?.maintenance_procedure_json.map((entry) => {
            return <Text dangerouslySetInnerHTML={{ __html: serialize(entry) }} />;
          })}
        </>
      ) : null}
    </Wrapper>
  );
};

export default ViewNewLayoutMelDetails;
