import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import { Defect } from '../../../models/defects';
import { Label } from '../../CommonStyledComponents/CommonStyledComponents';
import FileViewer from '../../TFFileViewer/fileViewer';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import TFDropdown from '../../TFDropdown/TFDropdown';
import { DashboardState } from '../../../models';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface EditDefectDrawerDetailsProps {
  defectData: Defect;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  formChanged: boolean;
}

const Header = styled.div`
  font-weight: 600;
  color: ${({ theme }): string => theme.colours.black};
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
`;

const ReportedByTypeSection = styled.div`
  grid-column: span 2;
  @media (max-width: 451px) {
    grid-column: span 1;
  }
`;

const EditDefectDrawerDetails: React.FC<EditDefectDrawerDetailsProps> = ({
  defectData,
  updateDefectData,
  formChanged,
}) => {
  const [reportedByType, setReportedByType] = useState(null);

  const {
    userSettings,
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const aircraftOperatorId = id ? aircraftMap.get(id).operator_id : aircraftMap.get(id)?.operator_id;
  const currentUser = userSettings?.details?.people.find((person) => person?.organisation?.id === aircraftOperatorId);

  const defectRename = getOperatorSetting('defect_name', id);
  const reportedByTypeEnabled = getOperatorSetting('defect_reported_by_type', id);
  const stageOfFlightEnabled = getOperatorSetting('stage_of_flight_enabled', id);
  const stageOfFlightText = getOperatorSetting('stage_of_flight_text', id);
  const stageOfFlightOptions = getOperatorSetting('stage_of_flight_options', id);

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const originalAttachments = defectData?.attachments || [];
      const newAttachments = originalAttachments.concat(...base64Attachments);
      updateDefectData([{ value: newAttachments, key: 'attachments' }]);
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList: any[] = defectData?.attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    updateDefectData([{ value: newAttachmentsList, key: 'attachments' }]);
  };

  const localStageOfFlightOptions = useMemo(() => {
    if (stageOfFlightOptions?.length) {
      return stageOfFlightOptions.map((option) => ({
        title: option,
        value: option,
      }));
    }
    return [];
  }, [stageOfFlightOptions]);

  useEffect(() => {
    if (defectData?.reported_by_type) {
      setReportedByType(defectData?.reported_by_type);
    } else if (['Pilot', 'ReliefPilot', 'Admin'].includes(currentUser?.position)) {
      updateDefectData([{ value: 'pilot', key: 'reported_by_type' }]);
      setReportedByType('pilot');
    } else if (['Crew', 'FlightAttendant'].includes(currentUser?.position)) {
      updateDefectData([{ value: 'cabin', key: 'reported_by_type' }]);
      setReportedByType('cabin');
    } else if (['Camo', 'Engineer', 'OpsController'].includes(currentUser?.position)) {
      updateDefectData([{ value: 'maintenance', key: 'reported_by_type' }]);
      setReportedByType('maintenance');
    }
  }, [defectData, currentUser, updateDefectData]);

  return (
    <div>
      <Header fontWeight={600} data-testid="EditDefectDrawerDetails--Header">
        {formatMessage({ id: 'form.labels.defectDetails' }, { field: defectRename })}
      </Header>
      <GridSection>
        <div data-testid="EditDefectDrawerDetails--DetailsSection">
          <Label edit>{formatMessage({ id: 'text.details' })}</Label>
          <StyledTextarea
            id="details"
            name="details"
            data-testid="EditDefectDrawerDetails--TextArea"
            rows={5}
            onChange={(e): void => {
              updateDefectData([{ value: e.target.value, key: 'details' }]);
            }}
            value={defectData?.details}
          />
        </div>
        <div data-testid="EditDefectDrawerDetails--AttachmentsSection">
          <Label edit>{formatMessage({ id: 'text.attachments' })}</Label>
          <FileViewer
            attachments={defectData?.attachments}
            handleDeleteClick={handleDelete}
            addAttachment={handleAttachmentUpload}
          />
        </div>
        {reportedByTypeEnabled ? (
          <ReportedByTypeSection data-testid="EditDefectDrawerDetails--ReportedByTypeSection">
            <TFRadioInput
              options={[
                { title: 'Pilot', value: 'pilot' },
                { title: 'Maintenance', value: 'maintenance' },
                { title: 'Cabin', value: 'cabin' },
              ]}
              onChange={(value): void => {
                updateDefectData([{ value, key: 'reported_by_type' }]);
              }}
              value={reportedByType}
              label={formatMessage({ id: 'text.reportedBy' })}
              id="ReportedByType"
            />
          </ReportedByTypeSection>
        ) : null}
        {stageOfFlightEnabled ? (
          <div data-testid="EditDefectDrawerDetails--StageOfFlightSection">
            <TFDropdown
              options={localStageOfFlightOptions}
              onSelect={(value): void => {
                updateDefectData([{ value: value.value, key: 'stage_of_flight' }]);
              }}
              id="stage_of_flight"
              label={stageOfFlightText}
              initialValue={{ title: defectData?.stage_of_flight, value: defectData?.stage_of_flight }}
            />
          </div>
        ) : null}
      </GridSection>
      {!defectData?.deferred ? (
        <ManuallyEnterMELDetails
          defect={defectData}
          noMEL
          updateDefectData={updateDefectData}
          formChanged={formChanged}
        />
      ) : null}
    </div>
  );
};

export default EditDefectDrawerDetails;
