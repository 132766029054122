import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Divider, Label } from '../../CommonStyledComponents/CommonStyledComponents';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';

interface ViewDefectDrawerReportedByProps {
  defectData: Defect;
}

const StyledImg = styled.img`
  height: 70px;
  max-width: 240px;
  @media (max-width: 451px) {
    max-width: 180px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ViewDefectDrawerReportedBy: React.FC<ViewDefectDrawerReportedByProps> = ({ defectData }) => {
  const { userSettings } = useSelector((state: DashboardState) => state);
  const dateFormat = userSettings?.details?.date_format;
  const { formatMessage } = useIntl();
  return (
    <div>
      <Wrapper data-testid="ViewDefectDrawerReportedBy--Wrapper">
        <InnerWrapper data-testid="ViewDefectDrawerReportedBy--InnerWrapper">
          <Label edit>{formatMessage({ id: 'text.reportedBy' })}</Label>
          <Label edit>
            {defectData?.reporter?.first_name || '-'} {defectData?.reporter?.last_name || null}
          </Label>
          {defectData?.signature_image_url && (
            <StyledImg
              alt="Signature"
              src={`data:image/png;base64, ${defectData?.signature_data}`}
              data-testid="ViewDefectDrawerReportedBy--Signature"
            />
          )}
        </InnerWrapper>
        <InnerWrapper data-testid="ViewDefectDrawerReportedBy--InnerWrapper-Date">
          <Label edit>{formatMessage({ id: 'form.labels.date' })}:</Label>
          <Label edit>{moment(defectData?.raised_at).utc().format(dateFormat) || '-'}</Label>
        </InnerWrapper>
      </Wrapper>
      <Divider />
    </div>
  );
};

export default ViewDefectDrawerReportedBy;
