import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider, SectionHeader } from '../../CommonStyledComponents/CommonStyledComponents';
import { Defect, DefectCategory, DefectType } from '../../../models/defects';
import useDefectDisplayDate from '../../../utils/useDefectDisplayDate';
import ViewDefectDrawerRectificationLimits from './ViewDefectDrawerRectificationLimits';
import DefectField from './DefectField';
import DisplayMelTable from './DisplayMelTable';

interface ViewDefectDrawerDeferralOptionsProps {
  defectData: Defect;
  requiredSettings: {
    defectRename: string;
    melNameOverride: string;
    cdlNameOverride: string;
    nefNameOverride: string;
    casNameOverride: string;
    otherNameOverride: string;
    showRichTextMel: boolean;
  };
}

const Wrapper = styled.div`
  margin-top: 20px;
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 10px;
  margin: 20px 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ViewDefectDrawerDeferralOptions: React.FC<ViewDefectDrawerDeferralOptionsProps> = ({
  defectData,
  requiredSettings,
}) => {
  const [defectTypeName, setDefectTypeName] = useState<string>('');

  const { formatMessage } = useIntl();

  const newDeferredUntilDate = useDefectDisplayDate({ defect: defectData, ignoreDatetimeDue: false });

  const isDefectTypeCDL = defectData?.defect_type === DefectType.CDL;

  const fieldsToDisplay = [
    {
      formatMessageId: 'text.defectTypeCaps',
      value: defectData?.deferred ? formatMessage({ id: 'text.deferred' }) : formatMessage({ id: 'text.notDeferred' }),
      formatMessageOverride: requiredSettings.defectRename,
    },
    { formatMessageId: 'text.deferralOptionsCaps', value: defectTypeName },
    { formatMessageId: 'title.categoryCaps', value: defectData?.display_data?.category },
    { formatMessageId: 'title.ataChapterCaps', value: defectData?.display_data?.ata },
    { formatMessageId: 'title.ataSectionCaps', value: defectData?.display_data?.ata_section },
    { formatMessageId: 'text.subSection', value: defectData?.book_item?.subsection_number?.toString() || '-' },
    { formatMessageId: 'text.subSubSection', value: defectData?.book_item?.subsubsection?.toString() || '-' },
    { formatMessageId: 'title.referenceCaps', value: defectData?.reference || '-' },
    { formatMessageId: 'title.deferredUntil', value: `${newDeferredUntilDate}z` },
  ];

  if (isDefectTypeCDL) {
    fieldsToDisplay.push(
      { formatMessageId: 'text.enrouteClimb', value: defectData?.rectification?.enroute_climb },
      { formatMessageId: 'text.maxDecrementPerWing', value: defectData?.rectification?.max_decrement_per_wing },
      { formatMessageId: 'text.approachAndLanding', value: defectData?.rectification?.approach_and_landing },
      { formatMessageId: 'text.takeoff', value: defectData?.rectification?.takeoff },
    );
  }

  useEffect(() => {
    const defectTypeRename = () => {
      const typeMap: { [key: string]: string } = {
        MEL: requiredSettings.melNameOverride,
        CDL: requiredSettings.cdlNameOverride,
        NEF: requiredSettings.nefNameOverride,
        CAS: requiredSettings.casNameOverride,
        Other: requiredSettings.otherNameOverride,
      };
      return typeMap[defectData?.defect_type] || defectData?.defect_type || '-';
    };
    setDefectTypeName(defectTypeRename());
  }, [
    defectData?.defect_type,
    requiredSettings.casNameOverride,
    requiredSettings.cdlNameOverride,
    requiredSettings.melNameOverride,
    requiredSettings.nefNameOverride,
    requiredSettings.otherNameOverride,
  ]);

  return (
    <Wrapper data-testid="ViewDefectDrawerDeferralOptions">
      <SectionHeader data-testid="ViewDefectDrawerDeferralOptions-Header">
        {formatMessage({ id: 'text.deferralOptions' })}
      </SectionHeader>
      <GridSection data-testid="ViewDefectDrawerDeferralOptions-Grid">
        {fieldsToDisplay.map((details) => {
          return (
            <DefectField
              key={details.formatMessageId}
              formatMessageId={details.formatMessageId}
              value={details.value}
              formatMessageOverride={details.formatMessageOverride}
            />
          );
        })}
      </GridSection>
      {defectData?.display_data?.category === DefectCategory.A ? (
        <ViewDefectDrawerRectificationLimits defectData={defectData} />
      ) : null}
      <Divider />
      {defectData?.defect_type === DefectType.MEL && defectData?.book_item?.id && (
        <DisplayMelTable defectData={defectData} requiredSettings={requiredSettings} />
      )}
    </Wrapper>
  );
};

export default ViewDefectDrawerDeferralOptions;
