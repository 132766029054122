/* eslint-disable no-nested-ternary */
import { Col, DatePicker, Dropdown, Form, Menu, Row, Table } from 'antd';
import { Search, Button as TFButton, Modal, Loading } from '@arcflight/tf-component-library';
import moment from 'moment';
import _, { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import NonStyledButton from '../NonStyledButton/NonStyledButton';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import {
  getAircraftTripEntries,
  updateEntry,
  updateTrip,
  deleteEntry,
  deleteUpdate,
  addUpdate,
  setTripsTableSource,
} from '../../models/trips/actions';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import { deleteFlightEntry, setFlightsDirty } from '../../models/flights/actions';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { FlightsActionTypes } from '../../models/flights';
import { addToast, ToastCategories, ToastTypes } from '../../models/toasts';
import TFTag from '../TFTag/TFTag';
import EmptyState from '../EmptyState/EmptyState';
import defaults from '../../utils/defaults';
import TFTooltip from '../TFTooltip/TFTooltip';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  setCloseDrawerData,
  setDrawerId,
} from '../../models/drawer';
import EmptyStateTrips from '../../assets/emptyState/empty-state-trips.svg';
import plusIcon from '../../assets/plus.svg';
import attachmentsIcon from '../../assets/icon-filetype-attachments.svg';
import { AircraftPermission, AircraftResource } from '../../models/aircraft';
import fratRiskColour from '../../utils/fratRiskLevelColourCalculator';
import TripUpdateDrawer from '../TripUpdateDrawer.tsx/TripUpdateDrawer';
import DocumentLink from '../DocumentLink/DocumentLink';
import TripDrawer from '../TripDrawer/TripDrawer';
import FlightDrawer from '../FlightDrawer/FlightDrawer';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import buildCompleteOption from '../../utils/tripUtils';
import styles from './index.module.less';

const EditableDate = ({ editable, value, onChange, dateFormat, id }) => (
  <span>
    {editable ? (
      <DatePicker
        value={value ? moment(value) : null}
        format={dateFormat}
        onChange={(e) => onChange(e, id)}
        data-test="datePickerInput"
      />
    ) : (
      moment(value).format(dateFormat)
    )}
  </span>
);

EditableDate.defaultProps = {
  editable: false,
  id: undefined,
  value: undefined,
};

EditableDate.propTypes = {
  editable: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

class TripsTable extends PureComponent {
  static propTypes = {
    aircraft: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    showSearchAdd: PropTypes.bool,
    tripsArr: PropTypes.array.isRequired,
    userSettings: PropTypes.object.isRequired,
    tripUpdate: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    source: PropTypes.string,
    lastFetched: PropTypes.number,
    match: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
  };

  static defaultProps = {
    aircraft: undefined,
    showSearchAdd: false,
    source: null,
    lastFetched: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      expRows: [],
      globalFilter: '',
      isInlineEdit: false,
      loading: true,
      sortedInfo: { columnKey: null, order: -1 },
      columns: [],
      tripUpdate: null,
      recentlySavedID: '',
      modalVisible: false,
      modalType: 'trip',
      modalId: null,
      modalTripId: null,
      modalTripNumber: null,
      reset: false,
      total: 0,
      page: 1,
      limit: 10,
      sort_order: '',
      sort_column: '',
      record_type: [],
      tripIdsBeingFetched: [],
    };
  }

  componentDidMount() {
    this.getAircraft();
    this.getColumns();
    this.getAircraftTrips();
  }

  componentDidUpdate(prevProps, prevState) {
    const { aircraft, tripsArr, lastFetched } = this.props;
    const { page, limit, sort_column, sort_order, record_type, globalFilter, tripIdsBeingFetched } = this.state;
    if (!_.isEqual(aircraft?.id, prevProps.aircraft?.id)) {
      this.getColumns();
      this.getAircraftTrips();
    }
    if (
      // We didn't have an aircraft before but we do now
      (aircraft && !prevProps.aircraft) ||
      // We did have an aircraft before but it was a different one
      (aircraft && prevProps.aircraft && aircraft.id !== prevProps.aircraft.id)
    ) {
      this.getFreshAircraftTrips();
    }
    if (!_.isEqual(tripsArr, prevProps.tripsArr)) {
      this.parseTripsMap();
    }

    if (
      lastFetched !== 0 &&
      lastFetched > prevProps.lastFetched &&
      tripsArr?.length === 0 &&
      prevProps?.tripsArr?.length === 0
    ) {
      this.stopLoading();
    }
    if (tripIdsBeingFetched !== prevState.tripIdsBeingFetched) {
      this.getColumns();
    }

    if (
      page !== prevState.page ||
      limit !== prevState.limit ||
      sort_column !== prevState.sort_column ||
      sort_order !== prevState.sort_order ||
      record_type !== prevState.record_type
    ) {
      this.getAircraftTrips();
    }
    if (globalFilter !== prevState.globalFilter) {
      this.getDebounceAircraftTrips();
    }

    const highlightedRow = document.querySelector(`.${styles.highlightedRow}`);
    if (highlightedRow) {
      window.scrollTo(0, highlightedRow.offsetTop);
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  getAircraft = () => {
    const {
      dispatch,
      match: {
        params: { id },
      },
    } = this.props;
    dispatch(getSingleAircraft({ payload: id }));
  };

  getAircraftTrips = () => {
    const { dispatch, aircraft } = this.props;
    const { page, limit, sort_order, sort_column, record_type, globalFilter } = this.state;
    this.setState({ loading: true }, () => {
      dispatch(
        getAircraftTripEntries({
          payload: { id: aircraft.id, page, limit, sort_order, sort_column, record_type, search: globalFilter },
        }),
      );
    });
  };

  getDebounceAircraftTrips = debounce(() => {
    this.setState({ page: 1 }, () => {
      this.getAircraftTrips();
    });
  }, 300);

  getFreshAircraftTrips = () => {
    const { dispatch, aircraft } = this.props;
    const { page, limit } = this.state;
    this.setState(
      {
        loading: true,
        data: [],
        tripUpdate: null,
      },
      () => {
        dispatch(
          getAircraftTripEntries({
            payload: { id: aircraft.id, page, limit },
          }),
        );
      },
    );
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  getColumns = async () => {
    const {
      intl: { formatMessage },
      aircraft,
      userSettings,
    } = this.props;
    const { sortedInfo, tripIdsBeingFetched } = this.state;

    const operatorSettings = userSettings?.details?.operators.find(
      (op) => aircraft?.operator_id === op.id,
    )?.operator_setting;

    const handleAttachmentClick = (record) => {
      const { dispatch } = this.props;
      dispatch(changeDrawerVisibility({ payload: true }));
      dispatch(
        changeDrawerContent({
          payload: {
            content: <TripDrawer trip={record} handleDelete={() => this.deleteTrip(record?.id)} scroll="attachments" />,
          },
        }),
      );
    };

    const columns = [
      {
        title: formatMessage({ id: 'title.tripNum' }),
        dataIndex: 'number',
        className: 'tripNumCol',
        sortOrder: sortedInfo.columnKey === 'number' && sortedInfo.order,
        render: (text, record) => {
          return (this.state.isInlineEdit && this.state.currentEditTrip === record?.id) || text ? (
            <div className={styles.loadingContainer}>
              {tripIdsBeingFetched?.includes(record?.id) ? (
                <>
                  <span className={styles.spinnerWrapper}>
                    <Loading
                      loading={tripIdsBeingFetched.includes(record?.id)}
                      hasTransparentBackground
                      contain
                      size={30}
                    />
                  </span>
                  <span className={styles.loadingText}>Generating PDF, please wait</span>
                </>
              ) : (
                <div className={styles.tripNumberWrapper}>
                  <span className={styles.tripNumber}>#{text}</span>
                  <DocumentLink
                    docType="srp"
                    id={record?.id}
                    aircraftId={aircraft?.id}
                    showLoadingText
                    number={`(${record?.srp_number})`}
                  />
                  {record?.type === 'Trip' && !this.props.aircraft?.standard_fields?.hide_journey_log?.enabled ? (
                    <DocumentLink docType="jl" id={record?.id} aircraftId={aircraft?.id} showLoadingText />
                  ) : null}
                </div>
              )}
            </div>
          ) : (
            '-'
          );
        },
        sorter: (a, b) => a.number - b.number,
      },
      {
        title: formatMessage({ id: 'title.status' }),
        dataIndex: 'status',
        className: 'status',
        render: (text, record) => {
          return (
            <>
              {(text === 'partial' || text === 'in_progress') && record?.captain?.id && (
                <TFTag colour="amber">{formatMessage({ id: 'status.inProgress' })}</TFTag>
              )}
              {text === 'error' && <TFTag colour="red">{formatMessage({ id: 'status.error' })}</TFTag>}
              {text === 'planned' && <TFTag>{formatMessage({ id: 'status.draft' })}</TFTag>}
              {text === 'complete' && <TFTag colour="green">{formatMessage({ id: 'status.complete' })}</TFTag>}
            </>
          );
        },
      },
      {
        title: formatMessage({ id: 'title.date' }),
        dataIndex: 'date',
        className: 'dateCol',
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        render: (text, record) => {
          if (record?.type === 'TripUpdate' || !record?.type) {
            if ((this.state.isInlineEdit && this.state.currentEditTrip === record?.id) || text) {
              return (
                <EditableDate
                  id={record?.id}
                  onChange={(value) => this.handleChange(value, record?.id, 'date')}
                  dateFormat={this.props.userSettings.dateFormat || defaults.defaultDateFormat}
                  editable={record?.editable}
                  value={text}
                />
              );
            }
            return '-';
          }
          return moment(text).format(this.props.userSettings.dateFormat || defaults.defaultDateFormat) || '-';
        },
        sorter: (a, b) => moment(a.date).diff(moment(b.date)),
      },
      {
        title: aircraft?.standard_fields?.callsign?.name_override || formatMessage({ id: 'title.callsign' }),
        dataIndex: 'callsign',
        className: 'callsignCol',
        sortOrder: sortedInfo.columnKey === 'callsign' && sortedInfo.order,
        render: (text) => {
          return text || '-';
        },
        sorter: (a, b) => {
          const aCallsign = a.callsign || '0';
          const bCallsign = b.callsign || '0';
          return aCallsign && bCallsign ? aCallsign.toString().localeCompare(bCallsign.toString()) : 0;
        },
      },
      {
        title: formatMessage({ id: 'title.hours' }),
        dataIndex: 'flight_seconds',
        className: 'hoursCol',
        render: (text) => {
          let hours = Math.floor(text / 3600);
          const remainingSeconds = text % 3600;
          let minutes = Math.floor(remainingSeconds / 60);
          minutes = String(minutes).padStart(2, '0');
          hours = String(hours).padStart(2, '0');
          return text ? `${hours}:${minutes}` : '-';
        },
      },
      {
        title: formatMessage({ id: 'title.landings' }),
        dataIndex: 'landings',
        className: 'landingsCol',
        render: (text) => {
          return text || '-';
        },
      },
      {
        title: formatMessage({ id: 'title.recordType' }),
        dataIndex: 'type',
        className: 'recordTypeCol',
        render: (text) => {
          if (text === formatMessage({ id: 'text.trip' })) {
            return <TFTag colour="cyan">{formatMessage({ id: 'text.trip' })}</TFTag>;
          }
          return <TFTag colour="amber">{operatorSettings?.trip_update}</TFTag>;
        },
        filters: [
          {
            text: formatMessage({ id: 'text.trip' }),
            value: 'Trip',
          },
          {
            text: operatorSettings?.trip_update,
            value: 'TripUpdate',
          },
        ],
        onFilter: (value, record) => record?.type === value,
      },
      {
        title: 'Attachments',
        dataIndex: 'attachments',
        className: styles.attachmentsCol,
        render: (text, record) => {
          if (record?.attachment_count) {
            return (
              <button
                type="button"
                data-testid="TripsTable-TripAttachmentsButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAttachmentClick(record);
                }}
                className={styles.attachmentsWrapper}
              >
                <img src={attachmentsIcon} alt="attachments" />
                <span>{record?.attachment_count}</span>
              </button>
            );
          }
          return null;
        },
      },
      {
        title: '',
        dataIndex: 'id',
        className: 'optionsCol',
        render: (text, trip) => {
          const { editable } = trip || false;
          const { dispatch } = this.props;

          const displayEditTrip = !editable && trip.type === 'Trip';
          const displayEditTripUpdate = !editable && (!trip.type || trip.type !== 'Trip');

          const dropdownOptions = {
            create: false,
            read: true,
            update: displayEditTrip,
            delete: displayEditTrip,
          };

          if (trip.number && trip.status !== 'complete') {
            dropdownOptions.extraItems = [buildCompleteOption(trip.id, dispatch, formatMessage)];
          }

          if (displayEditTrip) {
            return (
              <div className={styles.tripsAuthmenuWrapper}>
                <AuthDropdownMenu
                  menuStyle={{ position: 'fixed', zIndex: '100' }}
                  editText={<span>{formatMessage({ id: 'title.edit' })}</span>}
                  editCallback={() => {
                    dispatch(changeDrawerVisibility({ payload: true }));
                    dispatch(
                      changeDrawerContent({
                        payload: {
                          content: (
                            <TripDrawer
                              trip={trip}
                              handleDelete={() => this.deleteTrip(trip.id)}
                              deleteSector={this.deleteSector}
                              aircraft={aircraft}
                            />
                          ),
                        },
                      }),
                    );
                    dispatch(changeDrawerMode({ payload: 'edit' }));
                  }}
                  viewText={<span>{formatMessage({ id: 'form.button.view' })}</span>}
                  viewCallback={() => {
                    dispatch(changeDrawerVisibility({ payload: true }));
                    dispatch(
                      changeDrawerContent({
                        payload: {
                          content: (
                            <TripDrawer
                              trip={trip}
                              handleDelete={() => this.deleteTrip(trip.id)}
                              deleteSector={this.deleteSector}
                              aircraft={aircraft}
                            />
                          ),
                        },
                      }),
                    );
                  }}
                  resource={AircraftResource.TRIP}
                  handleDelete={() => this.deleteTrip(trip.id)}
                  aircraftId={aircraft.id}
                  options={dropdownOptions}
                />
              </div>
            );
          }

          if (displayEditTripUpdate) {
            return (
              <div className={styles.tripsAuthmenuWrapper}>
                <AuthDropdownMenu
                  menuStyle={{ position: 'fixed', zIndex: '100' }}
                  editText={<span>{formatMessage({ id: 'title.edit' })}</span>}
                  editCallback={() => {
                    dispatch(changeDrawerVisibility({ payload: true }));
                    dispatch(changeDrawerMode({ payload: 'edit' }));
                    dispatch(
                      changeDrawerContent({
                        payload: {
                          content: <TripUpdateDrawer />,
                        },
                      }),
                    );
                    dispatch(setDrawerId({ payload: trip.id }));
                  }}
                  customText="Override"
                  customCallback={() => {
                    dispatch(changeDrawerVisibility({ payload: true }));
                    dispatch(changeDrawerMode({ payload: 'override' }));
                    dispatch(
                      changeDrawerContent({
                        payload: {
                          content: <TripUpdateDrawer />,
                        },
                      }),
                    );
                    dispatch(setDrawerId({ payload: trip.id }));
                  }}
                  resource={AircraftResource.TRIP}
                  handleDelete={() => this.deleteTrip(trip.id)}
                  aircraftId={aircraft.id}
                  options={{
                    create: false,
                    read: false,
                    update: displayEditTripUpdate,
                    delete: displayEditTripUpdate,
                    custom: true,
                  }}
                />
              </div>
            );
          }

          return null;
        },
      },
    ];
    this.setState({ columns });
  };

  parseTripsMap = () => {
    const { tripsArr, total } = this.props;
    this.setState({
      data: tripsArr,
      expRows: tripsArr.map((item) => item.id),
      loading: false,
      tripUpdate: null,
      total,
    });
  };

  updateTripsMap = () => {
    const newTripUpdate = this.state.tripUpdate;
    if (newTripUpdate) {
      const data = this.props.tripsArr.filter((f) => {
        if (f) {
          return f.id !== newTripUpdate.id;
        }
        return f != null;
      });
      data.unshift(newTripUpdate);
      const expRowNum = [];
      data.forEach((d) => {
        expRowNum.push(d.id);
      });
      this.setState({
        data,
        expRows: expRowNum,
        loading: false,
      });
    }
  };

  handleAddTrip = () => {
    const { dispatch, aircraft } = this.props;
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: (
            <TripDrawer
              trip={null}
              handleDelete={(id) => this.deleteTrip(id)}
              deleteSector={this.deleteSector}
              aircraft={aircraft}
            />
          ),
        },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  menuAddNew = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Menu data-test="dropdownMenu" className={styles.menuOptions}>
        <Menu.Item onClick={this.handleAddTrip}>
          <NonStyledButton className={styles.menuButton}>{formatMessage({ id: 'text.trip' })}</NonStyledButton>
        </Menu.Item>
        <Menu.Item>
          <NonStyledButton
            className={styles.menuButton}
            data-test="linkToCreateNewTripUpdate"
            onClick={this.handleAddTripUpdate}
          >
            {formatMessage({ id: 'text.tripUpdate' })}
          </NonStyledButton>
        </Menu.Item>
      </Menu>
    );
  };

  dataDetails = (record) => {
    const {
      intl: { formatMessage },
      aircraft,
      dispatch,
      source,
      userSettings,
    } = this.props;
    const tripFlights = record?.flights;
    const items = [];

    const operatorSettings = userSettings?.details?.operators.find(
      (op) => aircraft?.operator_id === op.id,
    )?.operator_setting;

    const flightsInProgress = tripFlights ? tripFlights.some((flight) => flight.status === 'in_progress') : false;
    const sectorNameOverride = operatorSettings?.sector_name || formatMessage({ id: 'text.sector' });

    const displayFRAT = aircraft?.app_settings?.frat_enabled;

    if (tripFlights) {
      let sectorNumber = 0;
      for (const flight of tripFlights) {
        sectorNumber += 1;

        const flightId = record?.flights[sectorNumber - 1]?.id;

        const fratColour = fratRiskColour(flight?.flight_risk_assessment_result?.risk_level);

        const handleDrawerChange = () => {
          dispatch(changeDrawerVisibility({ payload: true }));
          dispatch(
            changeDrawerContent({
              payload: { content: <FlightDrawer /> },
            }),
          );
          dispatch(setDrawerId({ payload: flightId }));
        };

        const handleAttachmentClickFlight = (id) => {
          dispatch(changeDrawerVisibility({ payload: true }));
          dispatch(
            changeDrawerContent({
              payload: {
                content: <FlightDrawer scroll="attachments" />,
              },
            }),
          );
          dispatch(setDrawerId({ payload: id }));
        };

        const edit = (
          <button
            type="button"
            className={styles.viewButton}
            onClick={() => {
              dispatch(changeDrawerMode({ payload: 'edit' }));
              handleDrawerChange();
            }}
          >
            <span>{formatMessage({ id: 'text.edit' })}</span>
          </button>
        );

        const view = (
          <button
            type="button"
            onClick={() => {
              handleDrawerChange();
            }}
            className={styles.viewButton}
          >
            <span>{formatMessage({ id: 'form.button.view' })}</span>
          </button>
        );

        items.push(
          <Row key={flight.id} className={styles.sectorRow} gutter={16} data-test="singleFlightRow">
            <button
              type="button"
              onClick={() => {
                handleDrawerChange();
              }}
              className={styles.linkStyle}
            >
              <Col className={styles.sectorCol} span={3}>
                <span className={styles.sectorTitle}>
                  {sectorNameOverride} {sectorNumber}
                </span>
              </Col>
              <Col className={styles.sectorCol} span={4}>
                <div className={styles.statusWrapper}>
                  {flight?.abandonment_reason ? (
                    <TFTooltip
                      wrappedItem={
                        <TFTag margin="0 0 0 14px" colour="blue">
                          Abandoned
                        </TFTag>
                      }
                    >
                      {flight.abandonment_reason.replace(/_/g, ' ')}
                    </TFTooltip>
                  ) : (
                    <>
                      {flight.status === 'partial' && (
                        <TFTag margin="0 0 0 14px" colour="orange">
                          {formatMessage({ id: 'status.partial' })}
                        </TFTag>
                      )}
                      {flight.status === 'in_progress' && record.status === 'complete' && (
                        <>
                          <TFTag margin="0 0 0 14px" colour="blue">
                            {formatMessage({ id: 'status.inProgress' })}
                          </TFTag>
                          <TFTag margin="0 0 0 14px" colour="red">
                            {formatMessage({ id: 'status.postflightMissing' })}
                          </TFTag>
                        </>
                      )}
                      {flight.status === 'in_progress' && record.status !== 'complete' && (
                        <TFTag margin="0 0 0 14px" colour="blue">
                          {formatMessage({ id: 'status.inProgress' })}
                        </TFTag>
                      )}
                      {flight.status === 'error' && (
                        <TFTag margin="0 0 0 14px" colour="red">
                          {formatMessage({ id: 'status.error' })}
                        </TFTag>
                      )}
                      {flight.status === 'planned' && (
                        <TFTag margin="0 0 0 14px">{formatMessage({ id: 'status.draft' })}</TFTag>
                      )}
                      {flight.status === 'complete' && (
                        <TFTag margin="0 0 0 14px" colour="green">
                          {formatMessage({ id: 'status.complete' })}
                        </TFTag>
                      )}
                    </>
                  )}
                  {displayFRAT && flight?.flight_risk_assessment_result?.percentage ? (
                    <TFTag margin="8px 0 0 14px" colour={fratColour}>
                      {`FRAT ${flight.flight_risk_assessment_result.percentage}%`}
                    </TFTag>
                  ) : null}
                </div>
              </Col>
              <Col className={styles.sectorCol} span={7}>
                <div className={styles.sectorDetails} data-test="singleFlightPIC">
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.pic' })}</span>{' '}
                  {flight?.captain ? `${flight?.captain}` : '-'}
                </div>
                <div className={styles.sectorDetails} data-test="singleFlightSIC">
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.sic' })}</span>{' '}
                  {(flight?.first_officer && `${flight?.first_officer}`) || '-'}
                </div>
              </Col>
              <Col className={styles.sectorCol} span={5}>
                <div className={styles.sectorDetails}>
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.from' })}</span>{' '}
                  {flight.departure_airport || '-'}
                </div>
                <div className={styles.sectorDetails}>
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.to' })}</span>{' '}
                  {flight.arrival_airport || '-'}
                </div>
              </Col>
              <Col className={styles.sectorCol} span={5}>
                <div className={styles.sectorDetails}>
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.takeoff' })}</span>
                  {flight.time_takeoff ? moment.utc(flight.time_takeoff).format('HH:mm') : '-'}
                  {flight.time_takeoff ? <span className={styles.timeUnit}>Z</span> : ''}
                </div>
                <div className={styles.sectorDetails}>
                  <span className={styles.sectorLabel}>{formatMessage({ id: 'title.lanDing' })}</span>
                  {flight.time_landing ? moment.utc(flight.time_landing).format('HH:mm') : '-'}
                  {flight.time_landing ? <span className={styles.timeUnit}>Z</span> : ''}
                </div>
              </Col>
            </button>
            <Col className={styles.sectorCol} span={2}>
              {flight?.attachment_count > 0 ? (
                <button
                  type="button"
                  data-testid="TripsTable-SectorAttachmentsButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAttachmentClickFlight(flight?.id);
                  }}
                  className={styles.attachmentsWrapper}
                >
                  <img src={attachmentsIcon} alt="attachments" />
                  <span>{flight?.attachment_count}</span>
                </button>
              ) : null}
            </Col>
            <Col className={styles.sectorCol} span={1}>
              <AuthDropdownMenu
                menuStyle={{ position: 'absolute', zIndex: '100!important', left: '20px' }}
                editText={edit}
                editCallback={() =>
                  dispatch(
                    setTripsTableSource({
                      payload: {
                        source,
                      },
                    }),
                  )
                }
                viewText={view}
                resource={AircraftResource.TRIP}
                handleDelete={() => this.deleteSector(flight.id, record?.id, record?.number)}
                aircraftId={aircraft.id}
                options={{
                  create: false,
                  read: true,
                  update: !flightsInProgress,
                  delete: true,
                }}
              />
            </Col>
          </Row>,
        );
      }
    }
    return items;
  };

  filterTable = () => {
    const filteredBy = this.state.globalFilter.toLowerCase();
    const filterByKeys = [
      'aircraft_registration',
      'callsign',
      'number',
      'date',
      'captain',
      'first_officer',
      'date',
      'end_airport',
      'landings',
      'start_airport',
      'type',
      'flights',
    ];
    const filterByNestedKeys = [
      'first_name',
      'last_name',
      'arrival_airport',
      'departure_airport',
      'captain',
      'first_officer',
      'time_landing',
      'time_takeoff',
      'status',
    ];
    const data =
      filteredBy === null || ''
        ? this.props.tripsArr
        : this.props.tripsArr.filter((entry) =>
            Object.keys(entry).some((key) => {
              if (filterByKeys.includes(key)) {
                const value = entry[key];
                if (typeof value === 'number') {
                  return value.toString().includes(filteredBy);
                }
                if (typeof value === 'string') {
                  return value.toLowerCase().includes(filteredBy);
                }
                if (Array.isArray(value)) {
                  return value.some((nestedValue) => {
                    return Object.keys(nestedValue).some((nestedValueKey) => {
                      if (filterByNestedKeys.includes(nestedValueKey)) {
                        return (
                          typeof nestedValue[nestedValueKey] === 'string' &&
                          nestedValue[nestedValueKey].toLowerCase().includes(filteredBy)
                        );
                      }
                      return false;
                    });
                  });
                }
                if (typeof value === 'object' && value !== null) {
                  return Object.keys(value).some((nestedValueKey) => {
                    if (filterByNestedKeys.includes(nestedValueKey)) {
                      return (
                        typeof value[nestedValueKey] === 'string' &&
                        value[nestedValueKey].toLowerCase().includes(filteredBy)
                      );
                    }
                    return false;
                  });
                }
                return false;
              }
              return false;
            }),
          );
    this.setState({ data });
  };

  handleUpdate = async (target) => {
    const {
      intl: { formatMessage },
      dispatch,
    } = this.props;
    await new Promise((resolve) => {
      this.setState(
        {
          recentlySavedID: target.id,
        },
        resolve,
      );
    });
    if (!target.type || target.type === 'TripUpdate') {
      this.props.dispatch(
        updateEntry({
          payload: {
            id: target.id,
            trip: {
              number: target.number,
              srp_number: target.srp_number,
              date: moment(target.date).format('YYYY-MM-DD'),
            },
          },
        }),
      );
    } else {
      this.props.dispatch(
        updateTrip({
          payload: {
            id: target.id,
            trip: {
              number: target.number,
              srp_number: target.srp_number,
            },
            srp_number: true,
          },
        }),
      );
    }
    dispatch(
      addToast({
        payload: {
          title: formatMessage({ id: 'message.tripUpdatedSuccess' }),
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
          message: '',
        },
      }),
    );

    this.timeout = setTimeout(() => {
      this.setState({
        recentlySavedID: '',
      });
    }, 4000);
  };

  handleDelete = (target, newData) => {
    const { userSettings, aircraft } = this.props;
    const operatorSettings = userSettings?.details?.operators.find(
      (op) => aircraft?.operator_id === op.id,
    )?.operator_setting;
    const CRSNameOverride = operatorSettings?.approval_release_name;
    const callBack = ({ failed }) => {
      if (failed) {
        this.setState({ loading: false });
      } else {
        this.setState({ data: newData, loading: false });
      }
    };
    if (target.type && target.type === 'Trip') {
      this.props.dispatch(
        deleteEntry(
          {
            payload: {
              id: target.id,
              CRSNameOverride,
            },
          },
          callBack,
        ),
      );
      this.props.dispatch(
        setFlightsDirty({
          payload: true,
        }),
      );
      // eslint-disable-next-line no-unused-expressions
      target?.flights.forEach((flight) => {
        this.props.dispatch({
          type: FlightsActionTypes.DELETE_FLIGHT,
          payload: {
            id: flight.id,
            aircraftId: this.props.aircraft.id,
          },
        });
      });
    } else {
      this.props.dispatch(
        deleteUpdate(
          {
            payload: {
              id: target.id,
              CRSNameOverride,
            },
          },
          callBack,
        ),
      );
    }
  };

  handleFlightDelete = (target, newData) => {
    const {
      userSettings,
      aircraft,
      intl: { formatMessage },
    } = this.props;
    const operatorSettings = userSettings?.details?.operators.find(
      (op) => aircraft?.operator_id === op.id,
    )?.operator_setting;
    const sectorNameOverride = operatorSettings?.sector_name || formatMessage({ id: 'text.sector' });
    const callBack = () => {
      this.setState({ data: newData, loading: false });
    };
    if (target) {
      this.props.dispatch(
        deleteFlightEntry(
          {
            payload: {
              id: target,
              aircraftId: this.props.aircraft.id,
            },
            sectorNameOverride,
          },
          callBack,
        ),
      );
    }
  };

  handleChange = (value, id, column) => {
    const { data } = this.state;
    if ((column === 'number' || column === 'srp_number' || column === 'jl_number') && value.length > 0) {
      const isNumber = /^-{0,1}\d+$/.test(value);
      if (!isNumber) {
        return;
      }
    }
    // First we check whether to update the records or not
    if (data.some((item) => id === item.id)) {
      // Now that we know there was an update on an existing object we loop through them
      const newData = data.map((item) => {
        // If the object is the edited one
        if (item.id === id) {
          let newColumn;
          if (column === 'date' && value) {
            newColumn = moment(value).format('YYYY-MM-DD');
          } else {
            newColumn = value;
          }
          return {
            ...item,
            [column]: newColumn,
          };
        }
        return item;
      });
      this.setState({ data: newData });
    }
  };

  handleAddTripUpdate = () => {
    const { dispatch, aircraft } = this.props;
    this.setState({ loading: true });
    const newUpdate = dispatch(
      addUpdate({
        payload: {
          aircraftId: aircraft.id,
          trip: {
            date: moment().format('YYYY-MM-DD'),
          },
        },
      }),
    );
    Promise.all([newUpdate]).then((res) => {
      dispatch(changeDrawerVisibility({ payload: true }));
      dispatch(changeDrawerMode({ payload: 'add' }));
      dispatch(
        changeDrawerContent({
          payload: {
            content: <TripUpdateDrawer />,
          },
        }),
      );
      dispatch(setDrawerId({ payload: res[0]?.id }));
      dispatch(
        setCloseDrawerData({
          payload: { id: res[0]?.id, type: 'tripUpdate' },
        }),
      );
    });
  };

  removeIdFromFetchingArray = (id) => {
    this.setState((prevState) => {
      const newArray = prevState.tripIdsBeingFetched.filter((item) => item !== id);
      return { tripIdsBeingFetched: newArray };
    });
  };

  handleTableChange = (p, f, s) => {
    const recordType = [];
    if (f?.type?.includes('Trip')) recordType.push('trips');
    if (f?.type?.includes('TripUpdate')) recordType.push('trip_updates');
    this.setState({
      page: p.current,
      limit: p.pageSize,
      sort_order: s.order,
      sort_column: s.field,
      record_type: recordType,
    });
    if (s.columnKey) {
      this.setState({ sortedInfo: s }, () => {
        this.getColumns();
      });
    } else {
      this.setState({ sortedInfo: { columnKey: null, order: -1 } }, () => {
        this.getColumns();
      });
    }
  };

  save = (id) => {
    const {
      intl: { formatMessage },
      dispatch,
    } = this.props;
    this.setState({ loading: true });
    const target = this.state.data.find((item) => id === item.id);
    if (target) {
      const numberLength = target.number.toString().length;
      const srpLength = target.srp_number.toString().length;
      const invalidDate = target.date === 'Invalid date' || target.date === null;
      if ((numberLength && numberLength > 9) || (srpLength && srpLength > 9)) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.tooLongNumber' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message: '',
            },
          }),
        );
        this.setState({ loading: false });
      } else if (numberLength === 0) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.tripNumberBlank' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message: '',
            },
          }),
        );
        this.setState({ loading: false });
      } else if (srpLength === 0) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.tripSRPNumberBlank' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message: '',
            },
          }),
        );
        this.setState({ loading: false });
      } else if (parseInt(target.number, 10) < 1 || parseInt(target.srp_number, 10) < 1) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.tripNumberZero' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message: '',
            },
          }),
        );
        this.setState({ loading: false });
      } else if (invalidDate) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.invalidDate' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message: '',
            },
          }),
        );
        this.setState({ loading: false });
      } else {
        delete target.editable;
        this.handleUpdate(target);
      }
    }
  };

  cancel = (id) => {
    const { data } = this.state;
    const { tripsArr, tripUpdate } = this.props;
    const target = tripsArr.find((item) => id === item.id);
    const newData = data.map((item) => (item.id === id ? { ...target, editable: false } : item));
    if (tripUpdate && tripUpdate?.id) {
      const removedData = [...data];
      const newTarget = data.find((item) => id === item.id);
      const targetIndex = removedData.findIndex((item) => id === item.id);
      removedData.splice(targetIndex, 1);
      this.setState({
        tripUpdate: null,
      });
      this.handleDelete(newTarget, removedData);
    } else {
      this.setState({
        data: newData,
      });
    }
  };

  deleteTrip = (id) => {
    this.setState({ modalVisible: true, modalType: 'trip', modalId: id });
  };

  deleteSector = (id, tripId, tripNumber) => {
    this.setState({
      modalVisible: true,
      modalType: 'flight',
      modalId: id,
      modalTripId: tripId,
      modalTripNumber: tripNumber,
    });
  };

  handleModalDelete = () => {
    const { data, modalType, modalId, modalTripId } = this.state;
    const { dispatch } = this.props;
    this.setState({ loading: true });
    dispatch(changeDrawerVisibility({ payload: false }));
    const newData = [...data];
    if (modalType === 'trip') {
      const target = newData.find((item) => modalId === item.id);
      const targetIndex = newData.findIndex((item) => modalId === item.id);
      newData.splice(targetIndex, 1);
      this.handleDelete(target, newData);
      this.setState({ modalVisible: false, modalId: null, modalTripId: null, modalTripNumber: null });
    } else {
      const targetTrip = newData.find((item) => modalTripId === item.id);
      const targetTripIndex = newData.map((trip) => trip.id).indexOf(targetTrip.id);
      if (targetTrip.flights.length === 1) {
        newData.splice(targetTripIndex, 1);
        this.handleDelete(targetTrip, newData);
      } else {
        const targetSectorIndex = targetTrip.flights.findIndex((item) => modalId === item.id);
        targetTrip.flights.splice(targetSectorIndex, 1);
        newData[targetTripIndex] = targetTrip;
        this.handleFlightDelete(modalId, newData);
      }
      this.setState({ modalVisible: false, modalId: null, modalTripId: null, modalTripNumber: null });
    }
  };

  handleTripRowClick = (record) => {
    const { dispatch, aircraft } = this.props;
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: <TripDrawer trip={record} handleDelete={() => this.deleteTrip(record?.id)} aircraft={aircraft} />,
        },
      }),
    );
  };

  handleTripUpdateRowClick = (record) => {
    const { dispatch } = this.props;
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: <TripUpdateDrawer />,
        },
      }),
    );
    dispatch(setDrawerId({ payload: record?.id }));
  };

  resetFilters = () => {
    this.setState({ reset: true, globalFilter: '' }, () => this.filterTable());
  };

  render() {
    const {
      showSearchAdd,
      intl: { formatMessage },
      tripsArr,
      aircraft,
      userSettings,
    } = this.props;
    const { expRows, data, recentlySavedID, columns, loading, modalVisible, modalType, modalTripNumber, reset, total } =
      this.state;

    const operatorSettings = userSettings?.details?.operators.find(
      (op) => aircraft?.operator_id === op.id,
    )?.operator_setting;

    const sectorNameOverride = operatorSettings?.sector_name || formatMessage({ id: 'text.sector' });

    return (
      <div className={styles.tripTableWrapper} data-testid="TripsTable--TripsTableWrapper">
        {showSearchAdd && (
          <div className={styles.tableHeader}>
            <div className={styles.titleWrapper}>
              <span className={styles.tableTitle}>Trips</span>
            </div>
            <div className={styles.tableActions}>
              <div id="searchWrapper" className={styles.searchWrapper}>
                <Search
                  data-test="inputFilterBox"
                  onChange={(e) => {
                    this.setState({ globalFilter: e.target.value, reset: false }, () => {
                      this.filterTable();
                    });
                  }}
                  reset={reset}
                  placeholder={formatMessage({ id: 'form.placeholder.searchTrips' })}
                  onClear={() => this.setState({ globalFilter: '', reset: false }, () => this.filterTable())}
                />
              </div>
              <AircraftAuthenticationWrapper
                aircraftId={aircraft?.id}
                requiredResource={AircraftResource.TRIP}
                requiredPermissionLevel={AircraftPermission.CREATE}
              >
                <Dropdown overlay={this.menuAddNew} data-test="addNewDropdown" trigger={['click']}>
                  <div id="buttonWrapper" data-testid="TripsTable--ButtonWrapper" className={styles.buttonWrapper}>
                    <TFButton
                      size={ButtonSize.MEDIUM}
                      data-test="addNewButton"
                      type="primary"
                      className={styles.addNewButton}
                      icon="plus"
                    >
                      <span className={styles.buttonText}>
                        <img src={plusIcon} alt="plus icon" />{' '}
                        {window.innerWidth > 450
                          ? formatMessage({ id: 'form.button.addNew' })
                          : formatMessage({ id: 'form.button.add' })}
                      </span>
                    </TFButton>
                  </div>
                </Dropdown>
              </AircraftAuthenticationWrapper>
            </div>
          </div>
        )}
        <div className={styles.tableWrapper}>
          <Loading loading={loading} contain />
          {data.length ? (
            <Table
              onChange={this.handleTableChange}
              dataSource={data}
              rowKey={(record) => record?.id}
              rowClassName={(record) => (record?.id === recentlySavedID ? styles.highlightedRow : '')}
              columns={columns}
              pagination={{ showSizeChanger: true, total }}
              className={styles.tripsTable}
              expandedRowRender={this.dataDetails}
              expandedRowKeys={expRows}
              onExpandedRowsChange={this.expandedRowsChange}
              onRow={(record) => ({
                onClick: () => {
                  if (record?.type === 'Trip') {
                    this.handleTripRowClick(record);
                  } else {
                    this.handleTripUpdateRowClick(record);
                  }
                },
              })}
              scroll={{ x: true }}
              data-test="tripsTable"
            />
          ) : (
            <EmptyState
              image={EmptyStateTrips}
              text={tripsArr.length ? "We couldn't find any matching trips" : 'No trips'}
              subText={
                tripsArr.length
                  ? 'Try adjusting your filters or searching with another term.'
                  : 'You can add your first trip now.'
              }
              button={tripsArr.length ? 'Clear all' : 'Add new trip'}
              buttonAction={tripsArr.length ? this.resetFilters : this.handleAddTrip}
            />
          )}
        </div>
        <Modal width={420} isOpen={modalVisible} handleClose={() => this.setState({ modalVisible: false })}>
          <div className={styles.modalContentWrapper}>
            <div className={styles.modalTitle}>{formatMessage({ id: 'title.deleteItem' })}</div>
            {modalType === 'trip' ? (
              <div className={styles.modalMessage}>
                {`${formatMessage({
                  id: 'form.question.areYouSureDeleteTrip',
                })} ${formatMessage({
                  id: 'form.labels.cannotBeUndone',
                })}`}
              </div>
            ) : (
              <div className={styles.modalMessage}>
                {`${
                  modalTripNumber ? `${formatMessage({ id: 'text.tripNum' }) + modalTripNumber}:` : ''
                } ${formatMessage(
                  {
                    id: 'form.question.areYouSureDeleteSector',
                  },
                  { sector: sectorNameOverride.toLowerCase() },
                )} ${formatMessage({
                  id: 'form.labels.cannotBeUndone',
                })}`}
              </div>
            )}
            <div className={styles.modalButtonWrapper}>
              <div className={styles.submitButton}>
                <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={() => this.handleModalDelete()}>
                  Delete
                </TFButton>
              </div>
              <TFButton
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                primary={false}
                onClick={() => this.setState({ modalVisible: false })}
              >
                Cancel
              </TFButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(({ trips, userSettings }, { aircraft }) => {
    const tripsArr = Array.from(trips.tripsMap.values()).filter(
      (tripEntry) => aircraft && tripEntry.aircraft_id === aircraft.id,
    );
    let tripUpdate = {};
    if (trips.newTrip) {
      tripUpdate = trips.newTrip;
    }
    return {
      tripUpdate,
      tripsArr,
      lastFetched: trips?.lastFetched,
      userSettings,
      total: trips?.total,
      aircraftPermissions: userSettings.details.people[0].permission_groups[1].permissions,
    };
  }),
  Form.create(),
)(TripsTable);
