import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import ToolTip from '../../TFTooltip/TFTooltip';
import getAircraftStandardField from '../../../utils/getAircraftStandardField';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import DeferralOptionsHeader from './DeferralOptionsHeader';
import BookItemsSection from './BookItemsSection';
import { filterBookItems, reassignOriginalDefectData, useGetFieldOverrides } from './utils';

interface EditDeferralOptionsProps {
  defectData: Defect;
  originalDefectData: Defect;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  aircraftId: string;
  selectedAircraftId: string;
  formChanged: boolean;
  melItems: MELItem[];
  cdlItems: MELItem[];
  nefItems: MELItem[];
  bookItemsLoading: boolean;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }): string => theme.colours.black};
  margin: 20px 0;
  span {
    margin-right: 4px;
  }
`;

const BoldDiv = styled.div`
  font-weight: 600;
  @media (max-width: 451px) {
    max-width: 165px;
  }
`;

const RegularText = styled.span`
  font-weight: 400;
  margin-left: 4px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
`;

const EditDeferralOptions: React.FC<EditDeferralOptionsProps> = ({
  defectData,
  originalDefectData,
  updateDefectData,
  aircraftId,
  selectedAircraftId,
  formChanged,
  melItems,
  cdlItems,
  nefItems,
  bookItemsLoading,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [bookItems, setBookItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);

  const { formatMessage } = useIntl();

  const { MEL, CDL, NEF, CAS, Other } = {
    MEL: useGetFieldOverrides('deferral_type_mel', selectedAircraftId, aircraftId),
    CDL: useGetFieldOverrides('deferral_type_cdl', selectedAircraftId, aircraftId),
    NEF: useGetFieldOverrides('deferral_type_nef', selectedAircraftId, aircraftId),
    CAS: useGetFieldOverrides('deferral_type_cas', selectedAircraftId, aircraftId),
    Other: useGetFieldOverrides('deferral_type_other', selectedAircraftId, aircraftId),
  };

  const overrideDetails = {
    MEL: { nameOverride: MEL.nameOverride, longNameOverride: MEL.longNameOverride },
    CDL: { nameOverride: CDL.nameOverride, longNameOverride: CDL.longNameOverride },
    NEF: { nameOverride: NEF.nameOverride, longNameOverride: NEF.longNameOverride },
    CAS: { nameOverride: CAS.nameOverride, longNameOverride: CAS.longNameOverride },
    Other: { nameOverride: Other.nameOverride },
    showRichTextMel: getAircraftStandardField('rich_text_mel', selectedAircraftId || aircraftId)?.enabled,
  };

  const handleDefectTypeChange = (option): void => {
    let updateArray = [
      { value: option, key: 'defect_type' },
      { value: null, key: 'book_item' },
      { value: null, key: 'book_item_id' },
      { value: null, key: 'rectification_id' },
      { value: null, key: 'date_due' },
    ];
    if (option === 'MEL' || option === 'CDL' || option === 'NEF') {
      updateArray = reassignOriginalDefectData(originalDefectData, option) ?? updateArray;
    } else {
      updateArray.push({ value: {}, key: 'display_data' });
    }
    updateDefectData(updateArray);
  };

  const handleLocalBookItemChange = (itemId: string, rectId: string): void => {
    const bookItem = bookItems.find((item) => item.id === itemId);
    updateDefectData([
      { value: bookItem, key: 'book_item' },
      { value: bookItem.rectifications[0].operational_limitations, key: 'limitations' },
      { value: rectId, key: 'rectification_id' },
    ]);
  };

  const buildTooltipText = () => {
    const deferralTypes = [
      {
        type: 'MEL',
        nameOverride: MEL.nameOverride || formatMessage({ id: 'text.mel' }),
        longNameOverride: MEL.longNameOverride || formatMessage({ id: 'text.minimumEquipmentList' }),
        disabled: MEL.typeDisabled,
      },
      {
        type: 'CDL',
        nameOverride: CDL.nameOverride || formatMessage({ id: 'text.cdl' }),
        longNameOverride: CDL.longNameOverride || formatMessage({ id: 'text.configurationDeviationList' }),
        disabled: CDL.typeDisabled,
      },
      {
        type: 'NEF',
        nameOverride: NEF.nameOverride || formatMessage({ id: 'text.nef' }),
        longNameOverride: NEF.longNameOverride || formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' }),
        disabled: NEF.typeDisabled,
      },
      {
        type: 'CAS',
        nameOverride: CAS.nameOverride || formatMessage({ id: 'text.cas' }),
        longNameOverride: CAS.longNameOverride || formatMessage({ id: 'text.crewAlertingSystem' }),
        disabled: CAS.typeDisabled,
      },
    ];

    const filteredDeferralTypes = deferralTypes.filter((type) => !type.disabled);

    return filteredDeferralTypes.map((type) => {
      return (
        <BoldDiv key={type.type}>
          {type.nameOverride}
          <RegularText>{type.longNameOverride}</RegularText>
        </BoldDiv>
      );
    });
  };

  const deferralOptionsArray = [
    !MEL.typeDisabled && { title: MEL.nameOverride || 'MEL', value: 'MEL' },
    !CDL.typeDisabled && { title: CDL.nameOverride || 'CDL', value: 'CDL' },
    !NEF.typeDisabled && { title: NEF.nameOverride || 'NEF', value: 'NEF' },
    !CAS.typeDisabled && { title: CAS.nameOverride || 'CAS', value: 'CAS' },
    !Other.typeDisabled && { title: Other.nameOverride || 'Other', value: 'Other' },
  ].filter(Boolean);

  const buildDeferralOptionsRadioButtons = () => (
    <TFRadioInput
      options={deferralOptionsArray}
      onChange={handleDefectTypeChange}
      value={defectData?.defect_type}
      id="DefectType"
    />
  );

  useEffect(() => {
    if (defectData?.defect_type === 'MEL' && melItems?.length > 0) {
      setBookItems(melItems);
    } else if (defectData?.defect_type === 'CDL' && cdlItems?.length > 0) {
      setBookItems(cdlItems);
    } else if (defectData?.defect_type === 'NEF' && nefItems?.length > 0) {
      setBookItems(nefItems);
    } else if (defectData?.defect_type === 'CAS') {
      setBookItems([]);
    } else {
      setBookItems([]);
    }
  }, [defectData?.defect_type, melItems, cdlItems, nefItems]);

  useEffect(() => {
    if (searchInput) {
      const newItems = filterBookItems(bookItems, searchInput);
      setFilteredItems(newItems);
    } else {
      setFilteredItems(bookItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookItems, searchInput]);

  return (
    <div data-testid="EditDeferralOptions">
      <DeferralOptionsHeader defectData={defectData} updateDefectData={updateDefectData} />
      {defectData?.deferred ? (
        <>
          <Header data-testid="EditDeferralOptions--Header">
            <span>{formatMessage({ id: 'text.deferralOptions' })}</span>
            <ToolTip>{buildTooltipText()}</ToolTip>
          </Header>
          <RadioButtonWrapper data-testid="EditDeferralOptions--RadioButtonWrapper">
            {buildDeferralOptionsRadioButtons()}
          </RadioButtonWrapper>
          {defectData?.defect_type ? (
            <BookItemsSection
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              formChanged={formChanged}
              defectData={defectData}
              updateDefectData={updateDefectData}
              bookItems={bookItems}
              filteredItems={filteredItems}
              isBookItemsLoading={bookItemsLoading}
              overrideDetails={overrideDetails}
              handleLocalBookItemChange={handleLocalBookItemChange}
              aircraftId={aircraftId}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default EditDeferralOptions;
