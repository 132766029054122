import React from 'react';
import { Defect } from '../../../models/defects';
import { Flight } from '../../../models/flights';
import EditDefectDrawerHeader from './EditDefectDrawerHeader';

interface EditDefectDrawerProps {
  defectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isLoadingFlights: boolean;
  setAircraftId: (id: string) => void;
}

const EditDefectDrawer: React.FC<EditDefectDrawerProps> = ({
  defectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isLoadingFlights,
  setAircraftId,
}) => {
  return (
    <div>
      <EditDefectDrawerHeader
        defectData={defectData}
        aircraftId={aircraftId}
        updateDefectData={updateDefectData}
        flightsData={flightsData}
        isLoadingFlights={isLoadingFlights}
        setAircraftId={setAircraftId}
      />
    </div>
  );
};

export default EditDefectDrawer;
