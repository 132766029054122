import { useQuery } from '@tanstack/react-query';
import { getNEFItems } from '../../api';

const useQueryGetNEFItems = ({
  payload,
  refetchOnWindowFocus = true,
  staleTime = 0,
  enabled = true,
}: {
  payload: { id: string };
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
  enabled?: boolean;
}): { data: any; isFetching: boolean } => {
  const { data, isFetching } = useQuery({
    queryKey: ['nef_items', payload],
    queryFn: () => getNEFItems(payload),
    refetchOnWindowFocus,
    staleTime,
    enabled,
  });

  return { data, isFetching };
};

export default useQueryGetNEFItems;
