import moment from 'moment';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect, DefectType } from '../../../models/defects';
import DrawerBanner from '../../TripDrawer/DrawerBanner';
import getAircraftStandardField from '../../../utils/getAircraftStandardField';
import { MELItem } from '../../../models/mels';

const INTERVAL_MAP = { B: 3, C: 10, D: 120 };

export const getNewDateDueBasedOnInterval = (interval: string, passedDate: string): string => {
  const date = passedDate ? moment(passedDate) : moment();

  const daysToAdd = INTERVAL_MAP[interval] || 0;

  return date.add(daysToAdd, 'days').format('YYYY-MM-DD');
};

export const handleDeferralTimeChange = (
  dateTime: string,
  defect: Defect,
  updateFunction: (changes: { value: string; key: string }[]) => void,
): void => {
  let dateDue = '';
  if (defect?.date_due) dateDue = defect?.date_due;

  const daysVariance = dateDue
    ? moment.duration(moment.utc(dateTime).diff(moment.utc(dateDue)))
    : moment.duration(moment.utc(dateTime).diff(moment.utc()));

  let newDateVariance = moment.utc().add(daysVariance, 'days').format('YYYY-MM-DD');

  if (dateDue) newDateVariance = moment.utc(dateDue).add(daysVariance, 'days').format('YYYY-MM-DD');

  const newDate = getNewDateDueBasedOnInterval(defect?.display_data?.category, newDateVariance);

  updateFunction([
    { value: moment.utc(dateTime).toISOString(), key: 'deferred_at' },
    { value: newDate, key: 'date_due' },
  ]);
};

export const createMELChapters = (melChapters) => {
  const chaptersArray = [];
  Object.keys(melChapters).forEach((chapter) => {
    const childrenArray = [];
    if (melChapters[chapter]?.children && Object.keys(melChapters[chapter]?.children)?.length) {
      Object.keys(melChapters[chapter].children).forEach((section) => {
        childrenArray.push({
          value: section,
          title: `${section} - ${melChapters[chapter]?.children[section]}`,
        });
      });
    }
    chaptersArray.push({
      value: `${chapter} - ${melChapters[chapter]?.title}`,
      title: `${chapter} - ${melChapters[chapter]?.title}`,
      sections: childrenArray,
    });
  });
  return chaptersArray;
};

const ThirdPartyWrapper = styled.span`
  font-size: 18px;
  margin-left: 4px;
  span {
    margin-left: 4px;
  }
`;

export const displayThirdPartyReferences = (defectData: Defect): ReactElement | null => {
  if (!defectData?.third_party_references || Object.entries(defectData?.third_party_references).length === 0) {
    return null;
  }
  return (
    <ThirdPartyWrapper data-testid="DefectHeader--ThirdPartyReferences">
      {Object.entries(defectData?.third_party_references).map(([key, value]) => (
        <span key={key}>
          {`- ${key}:`} <strong>{`${value}\n`}</strong>
        </span>
      ))}
    </ThirdPartyWrapper>
  );
};

const hasRectificationIntervalExtension = (defectData: Defect): boolean => {
  return !!defectData?.rectification_interval_extension;
};

const hasExtensionDateDue = (defectData: Defect): boolean => {
  return (
    hasRectificationIntervalExtension(defectData) && !!defectData?.rectification_interval_extension.extension_date_due
  );
};

export const useDrawerBanner = (defectData: Defect): ReactElement | null => {
  const { formatMessage } = useIntl();
  if (hasRectificationIntervalExtension(defectData) && !hasExtensionDateDue(defectData)) {
    return <DrawerBanner message={formatMessage({ id: 'text.defectWillNotExpire' })} />;
  }

  return null;
};

export const useDisplayDefectTypeLabel = (
  defectData: Defect,
  overrideDetails: Record<string, { nameOverride?: string; longNameOverride?: string }>,
): string => {
  const { formatMessage } = useIntl();
  const type = defectData?.defect_type;
  const labels = {
    MEL: `${overrideDetails?.MEL?.nameOverride || 'MEL'} - ${
      overrideDetails?.MEL?.longNameOverride || formatMessage({ id: 'text.minimumEquipmentList' })
    }`,
    CDL: `${overrideDetails?.CDL?.nameOverride || 'CDL'} - ${
      overrideDetails?.CDL?.longNameOverride || formatMessage({ id: 'text.configurationDeviationList' })
    }`,
    NEF: `${overrideDetails?.NEF?.nameOverride || 'NEF'} - ${
      overrideDetails?.NEF?.longNameOverride || formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })
    }`,
    CAS: `${overrideDetails?.CAS?.nameOverride || 'CAS'} - ${
      overrideDetails?.CAS?.longNameOverride || formatMessage({ id: 'text.crewAlertingSystem' })
    }`,
    Other: `${overrideDetails?.Other?.nameOverride || 'Other'}`,
  };
  return labels[type] || '';
};

export const useGetFieldOverrides = (
  fieldType: string,
  aircraftId: string,
  id: string,
): { nameOverride?: string; longNameOverride?: string; typeDisabled?: boolean } => {
  const field = getAircraftStandardField(fieldType, aircraftId || id);
  return {
    nameOverride: field?.name_override,
    longNameOverride: field?.long_name_override,
    typeDisabled: field?.enabled === false,
  };
};

export const createBookItemChapterTitle = (item): string => {
  if (!item) return '';
  let chapterNumber = item?.chapter_number ? item.chapter_number.toString() : '00';
  if (item?.chapter_number?.toString() && item?.chapter_number?.toString()?.length === 1) {
    chapterNumber = `0${item?.chapter_number}`;
  }
  let sectionNumber = item?.section_number ? item.section_number.toString() : '00';
  if (item?.section_number?.toString() && item?.section_number?.toString()?.length === 1) {
    sectionNumber = `0${item?.section_number}`;
  }
  let subSectionNumber = item?.subsection_number ? item.subsection_number.toString() : '00';
  if (item?.subsection_number?.toString() && item?.subsection_number?.toString()?.length === 1) {
    subSectionNumber = `0${item?.subsection_number}`;
  }
  return `${chapterNumber}-${sectionNumber}-${subSectionNumber}`;
};

export const filterBookItems = (bookItems: Record<string, any>[], searchInputValue: string): Record<string, any>[] => {
  if (!bookItems) {
    return [];
  }
  if (!searchInputValue) {
    return bookItems;
  }
  const lowerCaseSearchInputValue = searchInputValue.toLowerCase();

  return bookItems.filter((item) => {
    const newItem = { ...item, builtTitle: createBookItemChapterTitle(item) };
    return Object.values(newItem).some((value) => {
      if (!value) return false;
      if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseSearchInputValue);
      }

      if (typeof value === 'number') {
        return (value as number).toString().includes(searchInputValue);
      }

      if (typeof value === 'object') {
        return Object.values(value).some((subValue) => {
          if (typeof subValue === 'string') {
            return subValue.toLowerCase().includes(lowerCaseSearchInputValue);
          }
          return false;
        });
      }
      return false;
    });
  });
};

export const reassignOriginalDefectData = (
  originalDefectData: Defect,
  defectType: string,
): { value: any; key: string }[] => {
  const baseUpdateArray = [
    { value: defectType, key: 'defect_type' },
    { value: originalDefectData?.book_item, key: 'book_item' },
    { value: originalDefectData?.rectification, key: 'rectification' },
    { value: originalDefectData?.rectification_id, key: 'rectification_id' },
    { value: originalDefectData?.date_due, key: 'date_due' },
  ];

  const isSameType = originalDefectData?.defect_type === defectType;
  if (isSameType) {
    return baseUpdateArray;
  }

  return null;
};

export const isCategoryRequired = (
  defectDeferred: boolean,
  defectType: string,
  melItems: MELItem[],
  cdlItems: MELItem[],
  nefItems: MELItem[],
): boolean => {
  let isRequired = true;
  if (!defectDeferred) isRequired = false;
  if (defectType === DefectType.MEL && melItems?.length > 0) {
    isRequired = false;
  }
  if (defectType === DefectType.CDL && cdlItems?.length > 0) {
    isRequired = false;
  }
  if (defectType === DefectType.NEF && nefItems?.length > 0) {
    isRequired = false;
  }
  return isRequired;
};
