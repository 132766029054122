import { useQuery } from '@tanstack/react-query';
import { getCDLItems } from '../../api';

const useQueryGetCDLItems = ({
  payload,
  refetchOnWindowFocus = true,
  staleTime = 0,
  enabled = true,
}: {
  payload: { id: string };
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
  enabled?: boolean;
}): {
  data: any;
  isFetching: boolean;
} => {
  const { data, isFetching } = useQuery({
    queryKey: ['cdl_items', payload],
    queryFn: () => getCDLItems(payload),
    refetchOnWindowFocus,
    staleTime,
    enabled,
  });
  return { data, isFetching };
};

export default useQueryGetCDLItems;
