import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import TFSelect from '../../TFSelect/TFSelect';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface AdditionalInformationProps {
  defect: Defect | null;
  updateDefectData: (changes: any[]) => void;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const TroubleShootingWrapper = styled.div`
  margin-bottom: 20px;
`;

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({ defect, updateDefectData }) => {
  const [reasonToDefer, setReasonToDefer] = useState(null);
  const [troubleshootingSteps, setTroubleshootingSteps] = useState(null);
  const [reasonToDeferOptions, setReasonToDeferOptions] = useState([]);

  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const troubleShootingSectionRequired = aircraftMap.get(id)?.standard_fields?.troubleshooting_steps?.required;
  const troubleShootingSectionRename = aircraftMap.get(id)?.standard_fields?.troubleshooting_steps?.name_override;
  const reasonToDeferRequired = aircraftMap.get(id)?.standard_fields?.reason_to_defer?.required;
  const reasonToDeferAsDropdown = aircraftMap.get(id)?.standard_fields?.reason_to_defer?.dropdown_enabled;

  const { formatMessage } = useIntl();
  const isMobile = window.innerWidth < 451;

  const handleSelectChange = (option): void => {
    const newValue = option.value;
    setReasonToDefer(newValue);
    updateDefectData([{ key: 'reason_to_defer', value: newValue }]);
  };

  useEffect(() => {
    if (defect) {
      if (defect?.reason_to_defer) {
        setReasonToDefer(defect?.reason_to_defer);
      } else {
        setReasonToDefer('');
      }
      if (defect?.troubleshooting_steps) {
        setTroubleshootingSteps(defect?.troubleshooting_steps);
      } else {
        setTroubleshootingSteps('');
      }
    }
  }, [defect]);

  useEffect(() => {
    if (aircraftMap.get(id)?.standard_fields?.reason_to_defer?.dropdown_options_selected) {
      const newOptions = aircraftMap
        .get(id)
        ?.standard_fields?.reason_to_defer?.dropdown_options_selected.map((option) => {
          return { title: option, value: option };
        });
      setReasonToDeferOptions(newOptions);
    }
  }, [aircraftMap, id]);

  return (
    <Wrapper data-testid="ViewAdditionalInformation">
      <FlexWrapper column>
        <Label marginBottom={20} fontWeight={600}>
          {formatMessage({ id: 'text.pleaseEnterAdditional' })}
        </Label>
        <TroubleShootingWrapper data-testid="ViewAdditionalInformation--TroubleshootingSteps">
          <Label marginBottom={10} fontWeight={500}>
            {formatMessage(
              { id: 'text.troubleshootingSteps' },
              { field: troubleShootingSectionRename || 'Troubleshooting steps' },
            )}{' '}
            {troubleShootingSectionRequired ? null : formatMessage({ id: 'form.labels.optional' })}
          </Label>
          <StyledInput
            width={isMobile ? 300 : 620}
            value={troubleshootingSteps}
            type="text"
            id="troubleshootingSteps"
            onChange={(e): void => {
              setTroubleshootingSteps(e.target.value);
              updateDefectData([{ value: e.target.value, key: 'troubleshooting_steps' }]);
            }}
          />
        </TroubleShootingWrapper>
        <Label marginBottom={10} fontWeight={500}>
          {formatMessage({ id: 'text.reasonToDefer' })}{' '}
          {reasonToDeferRequired ? null : formatMessage({ id: 'form.labels.optional' })}
        </Label>
        {reasonToDeferAsDropdown ? (
          <TFSelect
            options={reasonToDeferOptions}
            initial={reasonToDefer ? { title: reasonToDefer, value: reasonToDefer } : null}
            handleSelectChange={handleSelectChange}
            allowEmpty
          />
        ) : (
          <StyledInput
            width={isMobile ? 300 : 620}
            value={reasonToDefer}
            type="text"
            id="reasonToDefer"
            onChange={(e): void => {
              setReasonToDefer(e.target.value);
              updateDefectData([{ value: e.target.value, key: 'reason_to_defer' }]);
            }}
          />
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default AdditionalInformation;
