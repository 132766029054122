import React, { useState } from 'react';
import TFButton from '../../TFButton/TFButton';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import ViewMelDetails from './ViewMelDetails';

interface DisplayMelTableProps {
  defectData: any;
  requiredSettings: {
    showRichTextMel: boolean;
    melNameOverride: string;
  };
}

const DisplayMelTable: React.FC<DisplayMelTableProps> = ({ defectData, requiredSettings }) => {
  const [isMelTableVisible, setIsMelTableVisible] = useState(true);

  const handleMELTableClick = (): void => {
    setIsMelTableVisible(!isMelTableVisible);
  };

  return (
    <>
      <TFButton padding="0" clear handleClick={handleMELTableClick}>
        {isMelTableVisible
          ? `Hide ${requiredSettings.melNameOverride} Table`
          : `Show ${requiredSettings.melNameOverride} Table`}
      </TFButton>
      {requiredSettings.showRichTextMel ? (
        <ViewNewLayoutMelDetails defect={defectData} isMelTableVisible={isMelTableVisible} />
      ) : (
        <ViewMelDetails defect={defectData} isMelTableVisible={isMelTableVisible} />
      )}
    </>
  );
};

export default DisplayMelTable;
