/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem, MELRectification } from '../../../models/mels';
import melChapters from '../../../assets/melChapters.json';
import EmptyStateMel from '../../../assets/emptyState/empty-state-mel.svg';
import FlexWrapper from './FlexWrapper';
import StyledRadioInput from './StyledRadioInput';
import { createMELChapters } from './utils';

interface DisplayBookItemsProps {
  bookItems: Array<MELItem>;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
  handleBookItemChange: (itemId: string, rectId: string) => void;
  defect: Defect | null;
  searchInput?: string;
}

const CDLExtraFieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const MelItemWrapper = styled.div`
  padding: 35px 20px;
  display: flex;
`;

const FirstColumn = styled.div`
  display: flex;
  align-items: 'center';
  margin-right: 46px;
  span {
    color: rgba(36, 45, 65, 0.8);
  }
`;

const Title = styled.span`
  color: rgba(36, 45, 65, 0.6);
  width: ${({ wrapText }): string => (wrapText ? '75px' : 'max-content')};
  max-width: 500px;
`;

const SmallText = styled.span`
  color: rgba(36, 45, 65, 0.6);
  font-size: 12px;
  max-width: 500px;
  margin-right: ${({ margin }): string => (margin ? '20px' : '0')};
`;

const BlueText = styled.span`
  color: #126fd6;
  font-weight: 300;
  font-size: 12px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyStateImage = styled.img`
  height: 125px;
`;

const EmptyStateText = styled.div`
  font-size: 12px;
  color: #c0c0c0;
`;

const DisplayBookItems: React.FC<DisplayBookItemsProps> = ({
  bookItems,
  handleBookItemChange,
  defect,
  updateDefectData,
  searchInput,
}) => {
  const [selectedBookItem, setSelectedBookItem] = useState('');
  const [selectedRectificationId, setSelectedRectificationId] = useState('');
  const [MELChapters, setMELChapters] = useState([]);

  const { formatMessage } = useIntl();
  const selectedItem = document.getElementById(`${selectedBookItem}`);

  const IntervalOptions = {
    A: 'As per remarks',
    B: '3 days',
    C: '10 days',
    D: '120 days',
  };

  const getNewDateDueBasedOnInterval = (interval): string => {
    let date = defect.deferred_at ? moment(defect.deferred_at) : moment();
    if (interval === 'B') {
      date = date.add(3, 'days');
    }
    if (interval === 'C') {
      date = date.add(10, 'days');
    }
    if (interval === 'D') {
      date = date.add(120, 'days');
    }
    return date.format('YYYY-MM-DD');
  };

  const handleBookItemClick = (item: MELItem): void => {
    const melChapterValue = MELChapters.find(
      (chapter) => chapter.value?.split(' ')[0] === item.chapter_number?.toString(),
    );
    let foundRectification;
    let foundInterval;

    if (item.rectifications && item.rectifications.length > 0) {
      [foundRectification] = item.rectifications;
      foundInterval = foundRectification.interval;
    } else {
      foundInterval = null;
    }

    const newDateDue = getNewDateDueBasedOnInterval(foundInterval);
    setSelectedBookItem(item.id);
    setSelectedRectificationId(foundRectification?.id);
    handleBookItemChange(item.id, foundRectification?.id);
    updateDefectData([
      { value: item, key: 'book_item' },
      { value: item.id, key: 'book_item_id' },
      { value: foundRectification.id, key: 'rectification_id' },
      { value: foundInterval, key: 'rectification_category' },
      {
        value: { category: foundInterval, ata: melChapterValue?.value, ata_section: item.section_number },
        key: 'display_data',
      },
      { value: newDateDue, key: 'date_due' },
    ]);
  };

  const handleRectificationClick = (index: number, passedMelItem: MELItem, rect: MELRectification): void => {
    const melChapterValue = MELChapters.find(
      (chapter) => chapter.value?.split(' ')[0] === passedMelItem.chapter_number?.toString(),
    );
    const newDateDue = getNewDateDueBasedOnInterval(rect.interval);
    setSelectedBookItem(passedMelItem?.id);
    setSelectedRectificationId(rect.id);
    handleBookItemChange(passedMelItem?.id, rect.id);
    const updateArray = [
      { value: passedMelItem, key: 'book_item' },
      { value: passedMelItem.id, key: 'book_item_id' },
      { value: rect.id, key: 'rectification_id' },
      { value: rect.interval, key: 'rectification_category' },
      {
        value: { category: rect.interval, ata: melChapterValue?.value, ata_section: passedMelItem?.section_number },
        key: 'display_data',
      },
      { value: newDateDue, key: 'date_due' },
    ];
    updateDefectData(updateArray);
  };

  useEffect(() => {
    if (defect) {
      if (defect?.book_item) {
        setSelectedBookItem(defect?.book_item?.id);
      } else {
        setSelectedBookItem('');
      }
      if (defect?.rectification_id) {
        setSelectedRectificationId(defect?.rectification_id);
      } else {
        setSelectedRectificationId('');
      }
    }
  }, [defect]);

  useEffect(() => {
    if (selectedBookItem && selectedItem) {
      const wrapper = document.getElementById('BookItemWrapper');
      if (wrapper) {
        wrapper.scrollTo({ top: selectedItem.offsetTop + window.scrollY, behavior: 'smooth' });
      }
    }
  }, [selectedBookItem, selectedItem]);

  useEffect(() => {
    if (melChapters) {
      const chapters = createMELChapters(melChapters);
      setMELChapters(chapters);
    }
  }, [melChapters]);

  const rectifications = (items: Array<MELRectification>, passedMelItem: MELItem): Array<ReactElement> => {
    return items.map((item: MELRectification, index: number) => {
      return (
        <FlexWrapper marginTop={15} key={item.id} column identifier="BookItemsWrapper">
          <FlexWrapper marginBottom={10} alignItems="center">
            {items.length > 1 ? (
              <StyledRadioInput
                checked={item.id === selectedRectificationId}
                handleClick={(): void => {
                  handleRectificationClick(index, passedMelItem, item);
                }}
                id={`Rectification${item.id}`}
                value={`${item.id}`}
                name="rectificationGroup"
              />
            ) : null}
            <Title>{item.title || '-'}</Title>
          </FlexWrapper>
          <FlexWrapper marginBottom={10}>
            <SmallText margin data-testid="DisplayBookItems--IntervalSection">
              {formatMessage({ id: 'title.rectificationInterval' })}:{' '}
              <BoldText data-testid="DisplayBookItems--Interval">{item.interval}</BoldText>{' '}
              {IntervalOptions[item.interval]}
            </SmallText>
            <SmallText margin data-testid="DisplayBookItems--NumberInstalled">
              {formatMessage({ id: 'title.numberInstalled' })}: {item.number_installed}
            </SmallText>
            <SmallText data-testid="DisplayBookItems--NumberRequired">
              {formatMessage({ id: 'title.numberRequired' })}: {item.number_required}
            </SmallText>
          </FlexWrapper>
          <div>
            <SmallText data-testid="DisplayBookItems--Details">
              {item.operational_procedure ? '(O)' : null}
              {item.maintenance_procedure ? '(M)' : null}
              {item.placard_procedure ? '(*)' : null}
              {item.remarks ? `Remarks: ${item.remarks}` : null}
            </SmallText>
          </div>
          {defect?.defect_type === 'CDL' ? (
            <CDLExtraFieldsGrid>
              <div>
                <SmallText>
                  {formatMessage({ id: 'text.enrouteClimb' })}: {item.enroute_climb}
                </SmallText>
              </div>
              <div>
                <SmallText>
                  {formatMessage({ id: 'text.maxDecrementPerWing' })}: {item.max_decrement_per_wing}
                </SmallText>
              </div>
              <div>
                <SmallText>
                  {formatMessage({ id: 'text.approachAndLanding' })}: {item.approach_and_landing}
                </SmallText>
              </div>
              <div>
                <SmallText>
                  {formatMessage({ id: 'text.takeoff' })}: {item.takeoff}
                </SmallText>
              </div>
            </CDLExtraFieldsGrid>
          ) : null}
        </FlexWrapper>
      );
    });
  };

  const displayItems = bookItems;

  return (
    <div data-testid="DisplayBookItems">
      {displayItems && displayItems.length > 0 ? (
        displayItems.map((item) => {
          let chapterNumber = item?.chapter_number?.toString();
          if (item?.chapter_number?.toString() && item?.chapter_number?.toString()?.length === 1) {
            chapterNumber = `0${item?.chapter_number}`;
          }
          let sectionNumber = item?.section_number?.toString();
          if (item?.section_number?.toString() && item?.section_number?.toString()?.length === 1) {
            sectionNumber = `0${item?.section_number}`;
          }
          let subSectionNumber = item?.subsection_number?.toString();
          if (item?.subsection_number?.toString() && item?.subsection_number?.toString()?.length === 1) {
            subSectionNumber = `0${item?.subsection_number}`;
          }
          return (
            <MelItemWrapper id={item.id} key={item.id} data-testid="DisplayBookItems--BookWrapper">
              <FirstColumn>
                <StyledRadioInput
                  checked={selectedBookItem === item.id}
                  handleClick={(): void => {
                    handleBookItemClick(item);
                  }}
                  marginTop={2}
                  identifier="BookItem"
                  id={`BookItem${item.id}`}
                  value={`${item.id}`}
                  name="bookItemGroup"
                />
                <Title wrapText data-testid="DisplayBookItems--BookNumber">
                  {chapterNumber}-{sectionNumber}-{subSectionNumber}
                  {item?.subsubsection ? `-${item?.subsubsection}` : null}
                </Title>
              </FirstColumn>
              <div>
                <FlexWrapper marginBottom={5} column>
                  <FlexWrapper>
                    <Title data-testid="DisplayBookItems--BookTitle">{item.title}</Title>
                    {item?.cas_severity ? <span>{item?.cas_severity}</span> : null}
                  </FlexWrapper>
                  {item.rectifications && item.rectifications.length > 1 ? (
                    <BlueText data-testid="DisplayBookItems--MultipleOptions">
                      {formatMessage({ id: 'text.thisChapterContainsMultipleOptions' })}
                    </BlueText>
                  ) : null}
                </FlexWrapper>
                {item.rectifications && rectifications(item.rectifications, item)}
              </div>
            </MelItemWrapper>
          );
        })
      ) : (
        <EmptyStateWrapper>
          <EmptyStateImage src={EmptyStateMel} alt="no data" />
          <EmptyStateText>{`We couldn't find any results for '${searchInput}'`}</EmptyStateText>
        </EmptyStateWrapper>
      )}
    </div>
  );
};

DisplayBookItems.defaultProps = {
  searchInput: '',
};

export default DisplayBookItems;
