import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import SelectPerson from '../../SelectPerson';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface DeferralSignoffDetailsProps {
  defect: Defect;
  operatorID: string;
  updateDefectData: (changes: any[]) => void;
  signOffAsEngineer: boolean;
  setSignOffAsEngineer: (value: boolean) => void;
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '20px')};
  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

const SelectWrapper = styled.div`
  width: 300px;
  margin-right: 20px;
  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  margin: 20px 0;
  font-weight: 500;
  color: #242d41;
`;

const OPT_TEXT = '(opt.)';

const DeferralSignoffDetails: React.FC<DeferralSignoffDetailsProps> = ({
  defect,
  operatorID,
  updateDefectData,
  signOffAsEngineer,
  setSignOffAsEngineer,
}) => {
  const {
    userSettings: {
      details: { people },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [authReference, setAuthReference] = useState(null);
  const [part145Number, setPart145Number] = useState(null);
  const [licenceNumber, setLicenceNumber] = useState(null);

  const foundPerson = people.find((person) => person?.organisation?.id === operatorID);
  const isPubliclyDeferredDefect = !!(
    defect?.publicly_deferred_by_first_name && defect?.publicly_deferred_by_last_name
  );

  const part145ApprovalReferenceRename = getOperatorSetting('part_145_approval_number', id);
  const engineerRename = getOperatorSetting('engineer', id);
  const engineerLicenceNumberRename = getOperatorSetting('engineer_licence_number', id);
  const deferralApprovalReferenceRename = getOperatorSetting('defer_approval_reference_name', id);
  const deferUsingDefault = getOperatorSetting('defer_using_default', id);

  const handlePersonChange = (input: string): void => {
    updateDefectData([{ key: 'deferred_by_id', value: input }]);
  };

  useEffect(() => {
    if (!isPubliclyDeferredDefect && foundPerson && !(defect?.deferred_by_id || defect?.deferred_by?.id)) {
      updateDefectData([{ key: 'deferred_by_id', value: foundPerson.id }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPerson]);

  useEffect(() => {
    if (defect) {
      if (defect?.defer_approval_reference) {
        setAuthReference(defect?.defer_approval_reference);
      } else {
        setAuthReference('');
      }
      if (defect?.deferral_approval_number) {
        setPart145Number(defect?.deferral_approval_number);
      } else {
        setPart145Number('');
      }
      if (defect?.deferral_licence_number) {
        setLicenceNumber(defect?.deferral_licence_number);
      } else {
        setLicenceNumber('');
      }
    }
  }, [defect]);

  return (
    <>
      <Title data-testid="DeferralSignoffDetails-Title">{formatMessage({ id: 'text.deferralSignoff' })}</Title>
      <Label marginBottom={10} fontWeight={500} identifier="DeferralSignoffDetails-MaintenanceSignoff">
        {formatMessage({ id: 'text.maintenanceSignoff' })}
      </Label>
      <FlexWrapper>
        <RadioInputButton
          text="Yes"
          active={signOffAsEngineer}
          marginRight="8px"
          onClick={(): void => {
            setSignOffAsEngineer(true);
          }}
        />
        <RadioInputButton
          text="No"
          active={signOffAsEngineer === false}
          marginRight="8px"
          onClick={(): void => {
            setSignOffAsEngineer(false);
          }}
        />
      </FlexWrapper>
      <Wrapper>
        <SelectWrapper data-testid="DeferralSignoffDetails-DeferredBy">
          <Label marginBottom={5} fontWeight={500} identifier="DeferralSignoffDetails-DeferredBy">
            {formatMessage({ id: 'text.deferredBy' })}
          </Label>
          <SelectPerson
            addPerson
            onChange={(input: string) => {
              handlePersonChange(input);
            }}
            value={
              defect?.deferred_by_id ||
              defect?.deferred_by?.id ||
              (isPubliclyDeferredDefect ? null : foundPerson?.id) ||
              defect?.deferral_signature_data
            }
            organisation_id={operatorID}
            defectDrawer
          />
        </SelectWrapper>
        <InputWrapper marginRight="0" data-testid="DeferralSignoffDetails-InputWrapper">
          <Label marginBottom={5} fontWeight={500} identifier="DeferralSignoffDetails-DeferralApprovalReference">
            {deferralApprovalReferenceRename} {deferUsingDefault !== 'deferral_approval_reference' ? OPT_TEXT : null}
          </Label>
          <StyledInput
            id="approvalReference"
            type="text"
            width={300}
            value={authReference}
            onChange={(e): void => {
              setAuthReference(e.target.value);
              updateDefectData([{ value: e.target.value, key: 'defer_approval_reference' }]);
            }}
          />
        </InputWrapper>
      </Wrapper>
      {signOffAsEngineer ? (
        <Wrapper>
          <InputWrapper data-testid="DeferralSignoffDetails-InputWrapper">
            <Label marginBottom={5} fontWeight={500}>
              {part145ApprovalReferenceRename} {OPT_TEXT}
            </Label>
            <StyledInput
              id="part145number"
              type="text"
              width={300}
              value={part145Number}
              onChange={(e): void => {
                setPart145Number(e.target.value);
                updateDefectData([{ value: e.target.value, key: 'deferral_approval_number' }]);
              }}
            />
          </InputWrapper>
          <InputWrapper marginRight="0" data-testid="DeferralSignoffDetails-InputWrapper">
            <Label marginBottom={5} fontWeight={500}>
              {engineerRename} {engineerLicenceNumberRename} {OPT_TEXT}
            </Label>
            <StyledInput
              id="engineerLicenceNumber"
              type="text"
              width={300}
              value={licenceNumber}
              onChange={(e): void => {
                setLicenceNumber(e.target.value);
                updateDefectData([{ value: e.target.value, key: 'deferral_licence_number' }]);
              }}
            />
          </InputWrapper>
        </Wrapper>
      ) : null}
    </>
  );
};

export default DeferralSignoffDetails;
